import { useState } from 'react';
import firebase from 'firebase/app';

function useComments() {

  const [comments, setComments] = useState({});

  const getComments = (callback) => {
    // If no comments transition to 0 comments
    firebase.database().ref('/comments').once('value', snapshot => {
      if (!snapshot.val()) callback();
    });
    // Otherwise listen to new comments and add to state
    firebase.database().ref('/comments').on('child_added', snapshot => {
      const data = snapshot.val();
      setComments(prev => {
        const newComments = {...prev};
        newComments[snapshot.key] = {...data};
        return newComments;
      });
      callback();
    });
  };

  return {
    comments,
    getComments
  }

}

export default useComments;