import { useState } from 'react';
import firebase from 'firebase/app';

function useSessions() {

  const [count, setCount] = useState(0);

  /**
   * Returns a promise that retrieves session count from db and update state
   */
  const getSessionCount = () => {
    return firebase.database().ref('/sessionCount').once('value', snapshot => {
      let data = snapshot.val();
      setCount(data);
    });
  };

  /**
   * Add session data to db
   */
  const addSession = (data) => {
    firebase.database().ref('/sessions').push().set(data);
  };

  /**
   * Increment session count in db and state
   */
  const addSessionCount = () => {
    firebase.database().ref('/sessionCount').set(firebase.database.ServerValue.increment(1));
    setCount(prev => prev += 1);
  };

  return {
    count,
    getSessionCount,
    addSession,
    addSessionCount
  };
}

export default useSessions;