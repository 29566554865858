import './VisitCounter.css';

function VisitCounter(props) {
  return (
    <section>
      <div id='counter'>
        {props.mode === 'READY' && props.visitors}
        {props.mode === 'LOAD' && <i className='fas fa-spinner fa-spin' />}
      </div>
      visitors since 10/9/06
    </section>
  );
}

export default VisitCounter;