import bg from '../images/header-background.png';
import './Header.css';

function Header() {
  return (
    <>
      <header>
        <img src={bg} alt='Header background'/>
        <h1>Jon Liu Sucks!</h1>
      </header>
    </>
  );
}

export default Header;