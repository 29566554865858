import porn0 from '../images/porn0.jpg'
import porn1 from '../images/porn1.png';
import porn2 from '../images/porn2.jpg';
import porn3 from '../images/porn3.png';
import porn4 from '../images/porn4.png';
import porn5 from '../images/porn5.png';

function Pornstar() {

  const imgs = [
    porn0,
    porn1,
    porn2,
  ];
  const imgsList = imgs.map(img => 
    <>
      <img 
        className='poster' 
        src={img} 
        alt='Porn poster starring Jon Liu' 
        onClick={(e) => e.target.nextSibling.style.display = 'block'}
      />
      <div className='modal'>
        <span 
          className='close'
          onClick={(e) => e.target.parentNode.style.display = 'none'}
        >&times;</span>
        <img className='modal-content' src={img} />
      </div>
    </>
  );

  const imgs2 = [
    porn3,
    porn4,
    porn5
  ];
  const imgsList2 = imgs2.map(img => 
    <>
      <img 
        className='poster' 
        src={img} 
        alt='Porn poster starring Jon Liu' 
        onClick={(e) => e.target.nextSibling.style.display = 'block'}
      />
      <div className='modal'>
        <span 
          className='close'
          onClick={(e) => e.target.parentNode.style.display = 'none'}
        >&times;</span>
        <img className='modal-content' src={img} />
      </div>
    </>
  );

  return (
    <>
      <h3>
        I googled Jon Liu and look what I found? The guy’s a freaking porn star. Does it get any lamer than that? Stay away, ladies, stay away.
      </h3>
      {imgsList}
      <h3>
        He's a pick up artist too! So gross!
      </h3>
      {imgsList2}
    </>
  );
}

export default Pornstar;