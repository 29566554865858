import './Footer.css'

function Footer() {
  return (
    <footer>
      Made with ♥️ Based on <a href='http://tedmosbyisajerk.com/'>tedmosbyisajerk.com</a>
    </footer>
  )
}

export default Footer;