import { useEffect, useState } from 'react';
import TimeAgo from 'timeago-react';
import useComments from '../hooks/useComments';
import './Home.css';

// Modes
const LOAD = 'LOAD';
const READY = 'READY';

function Home() {

  const [commentsMode, setCommentsMode] = useState(LOAD);
  const { comments, getComments } = useComments();

  // Get Comments on load
  useEffect(() => {
    getComments(() => setCommentsMode(READY));
  }, []);
  
  const keys = Object.keys(comments).reverse();
  const commentsList = keys.map(key => 
      <article className='comment' key={key}>
        <header>Posted by {comments[key].name}</header>
        {comments[key].text}
        <footer><TimeAgo datetime={comments[key].timestamp} /></footer>
      </article>   
    );
  
  return (
    <>
      <h3>
        JON LIU sucks, Do not date him!!!<br />
        JON LIU sucks, Do not date him!!!<br />
        JON LIU sucks, Do not date him!!!<br />
        JON LIU sucks, Do not date him!!!<br />
        JON LIU sucks, Do not date him!!!<br />
      </h3>
      <div className='home-container'>
        <h2>
          Jon Liu: The Awful Truth
        </h2>
        <p>
          I started JonLiuSucks.com because Jon Liu Sucks! On October 9, 2006, I met Jon at that bar Strathcona in the Financial District. He seemed like a super great guy and used the fact that he's a big shot architect to hit on me. Beware! After we hooked up (whatever) he totally bailed on me while I was in the shower. Even worse, he left behind a LETTER claiming he was a ghost so he wouldn't have to talk to me again. Don't let this happen to you! If this website stops just one girl from hooking up with Jon Liu, it's all worth it. So, girls, stay away! And please feel free to post your thoughts on Jon. We must all work together to STOP JON LIU!
        </p>
        <footer>
          Posted by JonLiuSucks
          {/* <i className='fas fa-thumbs-up' style={{color: 'red'}} />
          <i className='far fa-thumbs-up' /> */}
        </footer>
      </div>
      <div className='comments-container'>
        {commentsMode === READY && (
          <>
            <h4>{commentsList.length} Comments</h4>
            {commentsList}
          </>
        )}
      </div>
    </>
  );
}

export default Home;