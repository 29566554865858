import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import VisitCounter from './VisitCounter';
import Home from './Home';
import Letter from './Letter';
import Portraits from './Portraits';
import Pornstar from './Pornstar';
import './SideNav.css';

function SideNav(props) {
  return (
    <Router>
      <div className='left-side-bar'>
        <nav>
          <Link to='/letter' className='blue'>The Infamous "Letter"</Link>
          <Link to='/' className='black'>Jon Liu: The Facts</Link>
          <Link to='/portraits' className='blue'>Portraits of Jon</Link>
          <Link to='/pornstar' className='black'>Great News: Jon is a friggin PORN STAR!</Link>
        </nav>

        <VisitCounter visitors={props.visitors} mode={props.mode} />
      </div>

      <main>
        <Switch>
          <Route path='/letter'>
            <Letter />
          </Route>
          <Route path='/portraits'>
            <Portraits />      
          </Route>
          <Route path='/pornstar'>
            <Pornstar />
          </Route>
          <Route path='/'>
            <Home />
          </Route>
        </Switch>
      </main>
    </Router>
  );
}

export default SideNav;