import letter from '../images/letter.png';

function Letter() {
  return (
    <>
      <h3>
        I took a shower and when I got out, this was the letter he left behind. Dear “Resident?” A ghost? Love, Serg? Seriously?! This guy is the biggest jerk on the planet.
      </h3>
      <img src={letter} alt='Fake letter' />
    </>
  );
}

export default Letter;