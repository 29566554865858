import portrait1 from '../images/portrait1.jpg';
import portrait2 from '../images/portrait2.png';

function Portraits() {

  const imgs = [
    portrait1,
    portrait2,
  ];
  const imgsList = imgs.map(img => 
    <>
      <img 
        className='portrait' 
        src={img} 
        alt='Drawing of Jon Liu dieing'
        onClick={(e) => e.target.nextSibling.style.display = 'block'}
      />
      <div className='modal'>
        <span 
          className='close'
          onClick={(e) => e.target.parentNode.style.display = 'none'}
        >&times;</span>
        <img className='modal-content' src={img} />
      </div>
    </>
  );

  return (
    <>
      <h3>Fates that would be too good for Jon Liu.</h3>
      {imgsList}
    </>
  );
}

export default Portraits;