import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import firebase from 'firebase/app';
import 'firebase/database';
import Header from './Header';
import SideNav from './SideNav';
import Footer from './Footer';
import useSessions from '../hooks/useSessions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
};

// Initialize Firebase
!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();

// Transition modes
const LOAD = 'LOAD';
const READY = 'READY';

function App() {

  const [countMode, setCountMode] = useState(LOAD);
  const { count, getSessionCount, addSession, addSessionCount } = useSessions();
  const [cookies, setCookie] = useCookies(['session']);

  const cookieOptions = {
    path: '/', // accessible on all pages
    maxAge: 3600, // expires in 1 hour (value in seconds)
  };

  const getIP = () => {
    return fetch('https://ipapi.co/json')
      .then(res => res.json())
  };

  // Update session based on cookie
  useEffect(() => {

    // If no cookie
    if (!cookies['session']) {

      const client = window.navigator.appCodeName;
      setCookie('session', client, cookieOptions);

      getSessionCount()
        .then(() => addSessionCount())
        .then(() => setCountMode(READY))
        .catch(err => console.log('Error', err));
      
      const timestamp = new Date();

      getIP()
        .then(data => {
          const sessionData = {
            timestamp: timestamp.getTime(),
            localTime: timestamp.toLocaleString(),
            client: client,
            city: data.city
          };
          addSession(sessionData);
        })
        .catch(err => {
          const sessionData = {
            timestamp: timestamp.getTime(),
            localTime: timestamp.toLocaleString(),
            client: client,
          };
          addSession(sessionData);
          console.log('Error', err)
        });

    } else {

      getSessionCount()
        .then(() => setCountMode(READY))
        .catch(err => console.log('Error', err));

    }
  }, [cookies]);  

  return (
    <div className='main-container'>
      <Header />
      <SideNav visitors={count} mode={countMode} />
      <Footer />
    </div>
  );
}

export default App;